<template>
  <div class="search_container">
    <Loading :visible="loading" />
    <Crumb :list="crumbList" :num="pageTotal" @onClick="handleCrumbClick" />
    <div
      class="search_condition"
      v-if="conditionDataList && conditionDataList.length"
    >
      <ul class="condition" bi-route="shaixuan">
        <li
          :class="{
            active: item.active,
          }"
          v-for="(item, index) in conditionDataList"
          :key="index"
          bi-click="paixu"
          :data-key="item.active ? item.dtitle || item.title : item.title"
          data-pingtai="pdd"
          @click="() => handleConditionChange(index)"
        >
          <span v-if="item.list">
            {{
              (item.list.find((curr) => curr.active == item.active) || {})
                .dtitle
            }}
          </span>
          <span v-else>
            {{ item.active ? item.dtitle || item.title : item.title }}
          </span>
        </li>
      </ul>
      <div class="search_page" v-if="pages">
        <span class="page_btn" @click="() => handlePageChange(page.pageId - 1)">
          <IconFont type="icon-zuojiantou" style="font-size: 14px" />
        </span>
        <span class="search_page_num">
          <b>{{ page.pageId }}</b>
          <span>/{{ pages }}</span>
        </span>
        <span class="page_btn" @click="() => handlePageChange(page.pageId + 1)">
          <IconFont type="icon-youjiantou" style="font-size: 14px" />
        </span>
      </div>
    </div>
    <div class="search_activity" v-if="activityList && activityList.length">
      <ul bi-route="shaixuan">
        <li
          v-for="(item, index) in activityList"
          :key="index"
          :class="{
            active: item.active,
          }"
          bi-click="paixu"
          :data-key="item.title"
          data-pingtai="pdd"
          @click="() => handleSxChange(index)"
        >
          <i></i>
          <span>{{ item.title }}</span>
        </li>
      </ul>
    </div>
    <div bi-route="goods" class="search_prod_list">
      <div
        v-for="(item, index) in data"
        :key="item.goodsSign"
        :class="{
          search_card: true,
          isLast: (index + 1) % 5 == 0,
        }"
        bi-click="list"
        :data-index="index"
        :data-gid="item.goodsSign"
        data-pingtai="pdd"
        @click="() => handleItemClick(item)"
      >
        <!-- <span class="sp_text" v-if="item.specialText && item.specialText[0]">
          {{ item.specialText && item.specialText[0] }}
        </span> -->
        <!-- <span class="active_tag"></span> -->
        <img
          class="main_img"
          v-lazy="`${item.goodsImageUrl}?imageView2/2/w/1300/q/80`"
          alt="商品主图"
        />
        <div class="search_card_title">
          <img :src="getShopImg()" alt="" />
          <span>{{ item.goodsDesc }}</span>
        </div>
        <div class="search_card_tags">
          <span class="tag" v-if="item.couponDiscount">
            券{{ item.couponDiscount }}元
          </span>
          <span class="tag" v-if="item.salesTip"
            >月售{{ item.salesTip | salesNum }}件</span
          >
        </div>
        <div class="search_card_price">
          <span>
            <span class="curr">￥</span>
            <span class="price">{{ item.minGroupPrice }}</span>
            <span class="price2" v-if="item.couponDiscount">{{
              AccSubFun(item)
            }}</span>
          </span>
        </div>
      </div>
    </div>
    <div v-if="!(data && data.length)" class="no_goods">
      <img
        src="https://cmsstaticnew.dataoke.com//images/quannogoods.png"
        alt=""
      />
      <p>抱歉~没有找到相关商品,减少一些筛选条件吧</p>
    </div>
    <Pagination
      :pageSize="page.pageSize"
      :total="pageTotal"
      :current="page.pageId"
      @onchange="handlePageChange"
    />
  </div>
</template>

<script>
import IconFont from "@/components/IconFont";
import Loading from "@/components/loading";
import { Crumb, Category, Pagination } from "../components";
import { guid } from "@/utils/utils";
import { getPddGoods, getPddGoodsOpen } from "@/services";
import { AccAdd } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  components: {
    IconFont,
    Crumb,
    Category,
    Pagination,
    Loading,
  },
  data() {
    return {
      data: [],
      crumbList: [], //面包屑
      page: {
        pageId: 1,
        pageSize: 100,
        listId:''
      },
      pageTotal: 0,
      loading: true,
      conditionDataList: [
        {
          title: "人气",
          type: "sortType",
          value: "0",
          active: false,
        },
        {
          title: "销量",
          type: "sortType",
          dtitle: "销量从高到低",
          value: "6",
          active: false,
        },
        {
          title: "价格",
          type: "sortType",
          list: [
            {
              dtitle: "价格",
              active: false,
              type: "sortType",
            },
            {
              dtitle: "价格从高到低",
              value: 10,
              active: 1,
              type: "sortType",
            },
            {
              dtitle: "价格从低到高",
              value: 9,
              active: 2,
              type: "sortType",
            },
          ],
          value: ["9", "10"],
          active: false,
        },
      ],
      activityList: [
        {
          type: "withCoupon",
          value: 1,
          title: "有券",
        },
      ],
      paramsMap: [
        {
          name: "page",
          mapName: "pageId",
        },
        {
          name: "pageSize",
          mapName: "pageSize",
        },
        {
          name: "keyword",
          mapName: "kw",
        },
        {
          name: "sortType",
          mapName: "sortType",
        },
        {
          name: "withCoupon",
          mapName: "withCoupon",
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    pages() {
      if (this.pageTotal && this.page.pageSize) {
        return Math.ceil(this.pageTotal / this.page.pageSize);
      }
      return null;
    },
    ...mapGetters(["config"]),
  },
  methods: {
    init() {
      let queryData = this.$route.query;
      if (!queryData.sortType) {
        queryData.sortType = 0;
      }
      delete queryData.t;
      let newList = this.activityList.map((item) => {
        for (let key in queryData) {
          if (key == item.type) {
            item.active = true;
          }
        }
        return item;
      });
      this.activityList = newList;
      //回显条件筛选
      let newConditionList = this.conditionDataList.map((item) => {
        if (item.value.includes(queryData[item.type])) {
          if (item.list) {
            item.active = this.handleActive(item.active, queryData.sortType);
          } else {
            item.active = true;
          }
        } else {
          item.active = false;
        }
        return item;
      });
      this.conditionDataList = newConditionList;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
        },
      });
      this.page.listId = "";
      this.getData({ pageId: 1, pageSize: 100 });
    },
    handleActive(active, sortType) {
      if (sortType == 10) {
        return 1;
      }
      if (sortType == 9) {
        return 2;
      }
      if (!active) {
        return 2;
      } else if (active == 1) {
        return 2;
      } else if (active == 2) {
        return 1;
      }
      return false;
    },
    handleConditionChange(dataIndex) {
      let obj = null;
      let queryData = this.$route.query;
      let list = this.conditionDataList.map((item, index) => {
        if (dataIndex === index) {
          if (item.list) {
            item.active = this.handleActive(item.active);
            obj = item.list.find((curr) => curr.active == item.active);
          } else {
            item.active = true;
            obj = item;
          }
        } else {
          item.active = false;
        }
        return item;
      });
      delete queryData.t;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
          [obj.type]: obj.value,
        },
      });
      this.conditionDataList = list;
      this.page.listId = "";
      this.getData({ pageId: 1, pageSize: 100 });
    },
    AccSubFun(item) {
      return AccAdd({
        num: [item.minGroupPrice, item.couponDiscount],
      });
    },
    getShopImg() {
      return require("../../../assets/search/pdd.png");
    },
    //点击商品
    handleItemClick(item) {
      let queryData = this.$route.query;
      // let url = this.$router.resolve({
      //   path: "/l/dd",
      //   query: {
      //     goods_sign: item.goodsSign,
      //     search_type: queryData.search_type,
      //     search_id: item.search_id,
      //     from: "dtk",
      //   },
      // });
      window.open(
        `?r=/l/dd&goods_sign=${item.goodsSign}&from=dtk&search_type=dd`,
        "_blank"
      );
      // window.open(url.href,'_blank');
    },
    handleSxChange(dataIndex) {
      let obj = null;
      this.page.listId = "";
      let queryData = {...this.$route.query};
      let item = this.activityList[dataIndex];
      if (item.active) {
        let list = this.activityList.map((item, index) => {
          if (dataIndex === index) {
            item.active = false;
            obj = item;
          }
          return item;
        });
        delete queryData[obj.type];
        delete queryData.t;
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...queryData,
          },
        });
        this.activityList = list;
        this.getData({ pageId: 1, pageSize: 100 });
        return;
      }
      let list = this.activityList.map((item, index) => {
        if (dataIndex === index) {
          item.active = true;
          obj = item;
        }
        return item;
      });
      delete queryData.t;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
          [obj.type]: obj.value,
        },
      });
      this.activityList = list;
      this.getData({ pageId: 1, pageSize: 100 });
    },
    handlePageChange(current) {
      if (current < 1 || current > this.pages) return;
      const pageParams = {
        ...this.page,
        pageId: current,
      };
      this.getData(pageParams);
    },
    getData(page = this.page, params) {
      if (!this.config.site_id) {
        return
      }
      this.loading = true;
      let configParams = {
      };
      if (this.config) {
        if (Number(this.config.pdd_commission_switch)) {
          let arr = [
            {
              range_from: Number(this.config.pdd_commission_limit) * 10,
              range_id: 2,
            },
          ];
          configParams.rangeList = JSON.stringify(arr);
        }
        if (Number(this.config.pdd_coupon_switch)) {
          configParams.withCoupon = 1;
        }
      }
      const urlQuery = this.$route.query;
      const urlParams = {
        ...urlQuery,
      };
      const obj = {
        ...page,
        ...params,
        ...urlParams,
      };
      const newParams = {};
      this.paramsMap.forEach((item) => {
        if (obj[item.mapName]) {
          newParams[item.name] = obj[item.mapName];
        }
      });
      this.updateClumbList();

      let endParams = {
        ...newParams,
        ...configParams,
        ...(this.config.pdd
          ? {
              uid: this.config.pdd.uid,
              pid: this.config.pdd.pid,
            }
          : {}),
      }
      endParams.listId = this.page.listId;
      getPddGoodsOpen({...endParams,...{
        keyword:decodeURIComponent(endParams.keyword || '')
      }})
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            this.data = res.data.goodsList;
            this.page = {
              ...this.page,
              pageId: page.pageId,
              listId: res.data.listId
            };
            this.pageTotal =
              res.data.totalCount > 10000 ? 10000 : res.data.totalCount;
          } else {
            this.data = [];
            this.pageTotal = 0;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    handleCrumbClick(item) {
      let queryData = this.$route.query;
      if (item.label === "活动") {
        let list = this.activityList.map((curr) => {
          if (curr.type === item.type) {
            curr.active = false;
          }
          return curr;
        });
        this.activityList = list;
      }
      delete queryData[item.type];
      delete queryData.t;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
        },
      });
      this.page.listId = "";
      this.getData({ pageId: 1, pageSize: 100 });
    },
    updateClumbList() {
      let queryData = this.$route.query;
      let list = [];
      this.activityList.forEach((item) => {
        let obj = {};
        for (let key in queryData) {
          if (key == item.type) {
            obj = {
              name: item.title,
              value: item.value,
              label: "活动",
              id: guid(),
              type: item.type,
            };
            list.push(obj);
          }
        }
      });

      this.crumbList = list;
    },
  },
  watch: {
    $route: function(state) {
      if (state.query.t) {
        let newList = this.activityList.map((item) => {
          item.active = false;
          return item;
        });
        this.activityList = newList;
        this.init();
      }
    },
    config() {
      this.init();
    }
  },
};
</script>

<style scoped lang="less">
@import url("../index.less");
.search_condition {
  background-color: #fff;
  height: 40px;
  width: 100%;
  line-height: 40px;
  position: relative;
  border: 1px solid rgba(241, 241, 241, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .condition {
    display: flex;
    max-width: 1108px;
    li {
      line-height: 40px;
      height: 39px;
      border-right: #f1f1f1 1px solid;
      padding: 0 20px;
      color: #666;
      position: relative;
      cursor: pointer;
      &:hover {
        color: #fe2e54;
      }
    }
    li.active {
      color: red;
      background: #fff;
      line-height: 40px;
      height: 40px;
    }
    li.search_form {
      width: 230px;
      margin-top: 1px;
      position: relative;
      z-index: 9;
      &:hover {
        background: #fff;
        box-shadow: 0 0 6px 0 rgba(170, 170, 170, 0.3);
        button {
          display: inline-block;
        }
      }
      span {
        height: 24px;
        line-height: 22px;
        padding: 0 2px;
      }
      .min_price,
      .max_price {
        width: 60px;
        height: 22px;
        line-height: 22px;
        padding: 0 4px;
        box-sizing: border-box;
        color: #999;
        border: 1px solid #eee;
        font-size: 12px;
        outline: 0;
      }
      button {
        display: none;
        width: 40px;
        height: 22px;
        border-radius: 2px;
        background: #ff6255;
        color: #fff;
        text-align: center;
        line-height: 22px;
        border: none;
        outline: 0;
        font-size: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
      button.btn_default {
        background-color: transparent;
        color: #ff6255;
      }
    }
  }
  .search_page {
    text-align: center;
    height: 26px;
    line-height: 26px;
    margin-right: 18px;
    b {
      color: #ff6255;
    }
    .page_btn {
      cursor: pointer;
      &:hover {
        color: #ff6255;
      }
    }
    span.page_btn.disable {
      cursor: default;
      &:hover {
        color: transparent;
      }
    }
    .search_page_num {
      font-size: 14px;
      margin: 0 8px;
      color: #666;
    }
  }
}
.search_activity {
  height: 40px;
  background: #fafafa;
  border: 1px solid rgba(241, 241, 241, 1);
  border-top: none;
  ul {
    display: flex;
    align-items: center;
    height: 100%;
    li.active {
      i {
        width: 12px;
        height: 12px;
        background: url("../../../assets/search/actcheck.png") center
          no-repeat#fff;
        background-size: 8px 6px;
        border: 1px solid #fe2e54;
      }
      span {
        color: #fe2e54;
      }
    }
    li {
      list-style: none;
      padding: 0 15px;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        i {
          border: 1px solid #fe2e54;
        }
        span {
          color: #fe2e54;
        }
      }
      i {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        border: 1px solid rgba(211, 211, 211, 1);
        background: #fff;
      }
      img {
        height: 12px;
        margin-left: 6px;
        vertical-align: inherit;
      }
      span {
        margin-left: 6px;
        font-size: 12px;
      }
    }
  }
}
.search_prod_list {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  margin-top: 12px;
  .search_card.isLast {
    margin: 0;
  }
  .search_card {
    padding: 20px 15px 0;
    position: relative;
    text-decoration: none;
    width: 230px;
    height: 315px;
    background: #fff;
    flex-shrink: 0;
    margin-bottom: 12px;
    list-style: none;
    box-sizing: border-box;
    line-height: 24px;
    margin-right: 12px;
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      .search_card_title span {
        color: #fe2e54 !important;
      }
    }
    span.sp_text {
      max-width: 190px;
      height: 20px;
      top: 20px;
      line-height: 20px;
      padding: 0 12px;
      background: #ff6594;
      background: linear-gradient(90deg, #ff6594 0, #ff2049 100%);
      border-radius: 0 0 10px;
      overflow: hidden;
      position: absolute;
      left: 20px;
      font-size: 12px;
      color: #fff;
      box-sizing: border-box;
    }
    span.active_tag {
      width: 190px;
      height: 35px;
      top: 176px;
      text-align: right;
      line-height: 18px;
      padding: 17px 5px 0 0;
      background: url("https://img.alicdn.com/imgextra/i1/2053469401/O1CN013InyzO2JJi7taWujh_!!2053469401.png")
        0px 0px / contain no-repeat;
      position: absolute;
      left: 20px;
      font-size: 12px;
      color: #fff;
      z-index: 10;
      box-sizing: border-box;
    }
    img.main_img {
      width: 190px;
      height: 190px;
      display: block;
      margin: 0 auto;
    }
    .search_card_title {
      display: flex;
      overflow: hidden;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: 9px;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 2px;
        font-size: 14px;
        color: #333;
        line-height: 20px;
      }
      img {
        width: 32px;
        height: 15px;
        flex-shrink: 0;
      }
    }
    .search_card_tags {
      min-height: 16px;
      display: flex;
      align-items: center;
      margin-top: 9px;
      span.tag {
        height: 16px;
        background: #ffe8e7;
        border-radius: 2px;
        padding: 0 3px;
        font-size: 12px;
        color: #ff2828;
        line-height: 16px;
        margin-right: 5px;
      }
    }
    .search_card_price {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 9px;
      .curr {
        font-size: 18px;
        color: #fe2e2e;
      }
      .price {
        font-size: 28px;
        color: #fe2e2e;
      }
      .price2 {
        font-size: 16px;
        color: #ababab;
        text-decoration: line-through;
        margin-left: 5px;
      }
      .sale {
        display: flex;
        font-size: 14px;
        color: #ababab;
      }
    }
  }
}
.no_goods {
  width: 1000px;
  padding: 0 0 50px;
  margin: 100px auto;
  text-align: center;
  img {
    width: 115px;
    height: 103px;
  }
  p {
    margin: 5px 0;
  }
}
</style>
